import React from 'react';
import { navigate } from 'gatsby';
import { Layout } from 'src/components/Layout';
import styles from './styles.module.css';
import { Spinner } from 'src/components/Spinner';
import { TextInput } from 'src/components/TextInput/component';
import { ArrowButton } from 'src/components/ArrowButton/component';
import { UserManager } from 'src/helpers/UserManager';
import { WorkSampleManager } from 'src/helpers/WorkSampleManager/WorkSampleManager';
import { showMessage } from 'src/components/Message';

interface Props {
	heading: string;
}

interface State {
	loading: boolean;
	success: boolean;
	id: number;
	user: number;
	title: string;
	description: string;
	url: string;
	existingID?: number;
	errors: boolean[];
}

export default class WorkSample extends React.Component<Props, State> {
	state: State = {
		loading: true,
		success: false,
		id: 0,
		user: 0,
		title: '',
		description: '',
		url: '',
		errors: [false, false, false],
	};

	async componentDidMount() {
		let { errors } = this.state;
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			// no user found so send them to sign in
			navigate('/sign-in?url=add-work-sample');
			return;
		} else {
			let urlParams;
			let existingID: number | undefined | null = undefined;
			// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
			if (typeof window !== 'undefined') {
				urlParams = new URLSearchParams(window.location.search);
			}
			existingID = urlParams ? Number(urlParams.get('existing')) : undefined;
			const result = await WorkSampleManager.getAllWorkSamplesForUser(user.id || -1);
			let validID = false;
			result.allWorkSamples.map((v) => {
				if (v.id == existingID) {
					validID = true;
					this.setState({
						id: v.id,
						title: v.title,
						description: v.description || '',
						url: v.url,
					});
				}
			});
			if (!validID) {
				if (existingID) console.warn(`Existing id is not valid`);
				existingID = undefined;
				errors = [true, false, true];
			}

			this.setState({
				user: user.id || -1,
				loading: false,
				existingID,
				errors,
			});
		}
	}

	saveWorkSample = async () => {
		const { user, title, description, url } = this.state;
		const hasValidResponses = title !== '' && url !== '' && this.isValidHttpUrl(url);
		if (hasValidResponses) {
			let result;
			if (this.state.existingID) {
				result = await WorkSampleManager.updateWorkSample({
					id: this.state.existingID,
					data: { title, description, url },
				});
			} else {
				result = await WorkSampleManager.createWorkSample({
					user,
					title,
					description,
					url,
				});
			}

			if (result.success && !result.error) {
				this.setState({ success: true });
				navigate('/profile');
			} else {
				this.setState({ success: false });
				showMessage({ caption: 'Failed to save work sample' });
			}
		} else {
			this.setState({ success: false });
			showMessage({ caption: 'Unable to save changes as some required fields have invalid data' });
			scrollTo(0, 0);
		}
	};

	goToProfileScreen = () => {
		navigate('/profile');
	};

	isValidHttpUrl = (value: string) => {
		let url;
		try {
			url = new URL(value);
		} catch (_) {
			return false;
		}
		return url.protocol === 'http:' || url.protocol === 'https:';
	};

	render() {
		const { loading, existingID, errors } = this.state;

		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div>
							<div className={styles.header}>
								<div className={styles.bookend}>
									<ArrowButton
										isBackButton={true}
										caption={'Back'}
										onClick={this.goToProfileScreen}
									/>
								</div>
								{existingID ? <h1>Edit Work Sample</h1> : <h1>Add Work Sample</h1>}
								<div className={styles.bookend}></div>
							</div>

							<hr />

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="Title of work sample:"
										isRequired
										value={this.state.title}
										onValueChanged={(value) => {
											errors[0] = !(value && value.length <= 120);
											this.setState({ title: value });
										}}
										validate={(value) => value && value.length <= 120}
										validationMsg={`This field is required and must be 120 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="Description of work sample:"
										isMultiLine
										value={this.state.description}
										onValueChanged={(value) => {
											errors[1] = !(value.length <= 6000);
											this.setState({ description: value });
										}}
										validate={(value) => value.length <= 6000}
										validationMsg={`This field must be 6000 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="URL to access work sample:"
										isRequired
										value={this.state.url}
										onValueChanged={(value) => {
											errors[2] = !(value && value.length <= 250);
											this.setState({ url: value });
										}}
										validate={(value) => value && value.length <= 250 && this.isValidHttpUrl(value)}
										validationMsg={`This field is required, must be a valid URL, and must be 250 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.footer}>
								<hr className={styles.hr} />
								<ArrowButton
									isBackButton={false}
									caption={'Save'}
									onClick={this.saveWorkSample}
									isDisabled={errors.includes(true)}
								/>
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
